import requiresAuth from "../guards/requiresAuth";
import requiresUserAndOrganisation from "../guards/requiresUserAndOrganisation";

export default [
  {
    path: "/vendors/reapit",
    name: "reapit.home",
    component: {
      template: `<router-view></router-view>`,
    },
    meta: {
      title: "Reapit",
    },
    children: [
      {
        path: "",
        name: "reapit",
        component: () =>
          import(
            /* webpackChunkName: "back-office.reapit" */ "../../views/back-office/integrations/reapit/ReapitLoginHandler.vue"
          ),
        meta: {
          title: "Start",
          layout: "ModalLayout",
        },
      },
      {
        path: "transaction/add",
        name: "reapit.transaction.add",
        beforeEnter: [requiresAuth, requiresUserAndOrganisation],
        component: () =>
          import(
            /* webpackChunkName: "back-office.reapit" */ "../../views/back-office/integrations/reapit/ReapitCreateTransaction.vue"
          ),
        meta: {
          title: "Add property",
          layout: "ModalLayout",
        },
      },
      {
        path: "sign-out",
        name: "reapit.logout",
        component: () =>
          import(
            /* webpackChunkName: "back-office.reapit" */ "../../views/back-office/integrations/reapit/ReapitLogout.vue"
          ),
        meta: {
          title: "Sign out",
          layout: "ModalLayout",
        },
      },
    ],
  },
];
