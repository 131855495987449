/**
 * TODO Split this is into multiple composable files as it's a bit of a dumping ground.
 * TODO Split out the organisations settings when that feature is ready.
 */
import axios from "axios";
import useAuth from "@/composables/auth";
import {
  MOVEREADY_ORGANISATION_API_URL,
  MOVEREADY_ADMIN_API_URL,
  MOVEREADY_FINANCES_API_URL,
} from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";
import useDownload from "@/composables/download";
import crmName from "@/enums/crm-name";
import Honeybadger from "@honeybadger-io/js";
import useOrganisationSettings from "@/composables/organisationSettings";
import useBackOfficeBranding from "@/composables/brandingBackOffice";

const { setBranding } = useBackOfficeBranding();
const { getAccessToken } = useAuth();
const { setOrganisationSettings } = useOrganisationSettings();
const { createDownloadableFromBlob, filenameFromHeaders } = useDownload();

const conveyancers = ref([]);
const isFetchingOrganisation = ref(false);
const isFetchingOrganisationUsers = ref(false);
const isFindingOrganisationOwners = ref(false);
const isFindingOrganisations = ref(false);
const organisation = ref(null);
const organisationOrders = ref([]);
const organisationOwners = ref([]);
const organisationUsers = ref([]);
const organisations = ref([]);

const organisationsWithOwners = computed(() =>
  interectOrganisationsAndOwners(organisations.value, organisationOwners.value)
);

/**
 * This will probably be a specific endpoint for conveyancers at some
 * point, so I'll emulate it for now. You'll need to fetch the organisations first.
 */
const findConveyancersByOrganisationId = (organisationIdToFind) => {
  const organisationMatch = organisations.value.find(
    ({ id }) => id === organisationIdToFind
  );
  conveyancers.value = organisationMatch?.branchConveyancers || [];
  return conveyancers.value;
};

const createOrganisation = async ({ organisationName, organisationLogo }) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();
  const newOrganisation = await axios.post(
    `${MOVEREADY_ADMIN_API_URL}/organisations`,
    {
      organisationName,
      organisationLogo,
    },
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    }
  );
  organisations.value = [...organisations.value, newOrganisation.data];

  return newOrganisation;
};

const updateOrganisationAsAdmin = async ({
  organisationName = null,
  organisationLogo = null,
  organisationId,
}) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();
  const newOrganisation = await axios.patch(
    `${MOVEREADY_ADMIN_API_URL}/organisations/${organisationId}`,
    {
      organisationName,
      organisationLogo,
    },
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    }
  );
  organisations.value = [...organisations.value, newOrganisation.data];

  return newOrganisation;
};

const findOrganisations = async () => {
  const appCheckToken = await getAppCheckToken();
  isFindingOrganisations.value = true;

  try {
    const response = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations`,
      {
        headers: {
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisations.value = sortOrganisationByOrganisationName(response.data);
  } catch (ex) {
    organisations.value = [];
    console.error(ex);
  } finally {
    isFindingOrganisations.value = false;
  }
};
const findOrganisationsAdmin = async () => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isFindingOrganisations.value = true;
  try {
    const response = await axios.get(
      `${MOVEREADY_ADMIN_API_URL}/organisations`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisations.value = sortOrganisationByOrganisationName(response.data);
  } catch (ex) {
    organisations.value = [];
    console.error(ex);
  } finally {
    isFindingOrganisations.value = false;
  }
};

const interectOrganisationsAndOwners = (organisations, owners) => {
  return organisations.map((organisation) => {
    return {
      ...organisation,
      owners: owners.filter(
        (owner) => owner.organisationId === organisation.id
      ),
    };
  });
};

const createOrganisationOwner = async ({
  organisationId,
  firstName,
  lastName,
  preferredName,
  email,
  phoneNumber,
  password,
}) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();
  const newOrganisationOwnerResponse = await axios.post(
    `${MOVEREADY_ADMIN_API_URL}/owners`,
    {
      organisationId,
      firstName,
      lastName,
      preferredName,
      email,
      phoneNumber,
      password,
    },
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    }
  );
  const newOwner = newOrganisationOwnerResponse.data;
  organisationOwners.value.push(newOwner);

  return newOwner;
};

const fetchOrganisationOwners = async () => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();
  isFindingOrganisationOwners.value = true;

  try {
    const response = await axios.get(`${MOVEREADY_ADMIN_API_URL}/owners`, {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    });
    organisationOwners.value = response.data;
  } catch (ex) {
    organisationOwners.value = [];
    console.error(ex);
  } finally {
    isFindingOrganisationOwners.value = false;
  }
};

const fetchOrganisation = async (organisationId) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();
  isFetchingOrganisation.value = true;

  try {
    const response = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisation.value = response.data;
    setOrganisationSettings(response.data?.preferences || {});
    setBranding(response.data?.preferences?.backOfficeBranding || {});
  } catch (ex) {
    organisation.value = null;
    console.error(ex);
  } finally {
    isFetchingOrganisation.value = false;
  }
};

const findOrganisationById = (organisationIdToFind) => {
  const organisationMatch = organisations.value.find(
    ({ id }) => id === organisationIdToFind
  );
  return organisationMatch;
};

const sortOrganisationByOrganisationName = (unsortedOrganisations) => {
  const sortedOrganisations = [...unsortedOrganisations].sort(
    (org, orgToCompare) => {
      const currentOrganisationName =
        org?.organisationName?.toLowerCase() || "";
      const comparisonOrganisationName =
        orgToCompare?.organisationName?.toLowerCase() || "";
      if (currentOrganisationName < comparisonOrganisationName) {
        return -1;
      }
      if (currentOrganisationName > comparisonOrganisationName) {
        return 1;
      }
      return 0;
    }
  );
  return sortedOrganisations;
};

const hasConveyancers = computed(() => !!conveyancers.value.length);

const fetchOrganisationByInviteToken = async (inviteToken) => {
  const appCheckToken = await getAppCheckToken();
  isFetchingOrganisation.value = true;
  try {
    const findOrganisationResponse = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/invite-token/${inviteToken}`,
      {
        headers: {
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisation.value = findOrganisationResponse.data;
  } catch (ex) {
    organisation.value = null;
    return null;
  } finally {
    isFetchingOrganisation.value = false;
  }
};

const organisationLogoUrl = computed(() => {
  const organisationLogo = organisation.value?.organisationLogo;

  if (!organisationLogo) return "";

  if (organisationLogo.startsWith("http")) return organisationLogo;

  return `/assets/vendors/${organisationLogo}`;
});

const organisationName = computed(
  () => organisation.value?.organisationName || ""
);

const fetchOrganisationUsers = async (organisationId) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();
  isFetchingOrganisationUsers.value = true;
  try {
    const organisationUsersResponse = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}/users`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisationUsers.value = organisationUsersResponse.data;
  } catch (ex) {
    organisationUsers.value = [];
  } finally {
    isFetchingOrganisationUsers.value = false;
  }
};

const getIntegrationsForAllUsers = (organisationUsers) => {
  return organisationUsers.reduce((acc, user) => {
    return [...acc, ...(user?.crmIntegrations || [])];
  }, []);
};

const isAltoIntegrationAvailable = computed(() => {
  const integrationsForAllUsers = getIntegrationsForAllUsers(
    organisationUsers.value
  );

  const hasAltoIntegration = integrationsForAllUsers.some(
    (integration) => integration?.crmName === crmName.ALTO
  );

  return hasAltoIntegration;
});

/**
 * Checking all users for valid Alto integration. In case
 * some users don't have it enabled, this will disable it for
 * all users in the organisation. In taht case the invite/accept
 * flow hasn't copied the integration record accross.
 */
const isAltoIntegrationActive = computed(() => {
  const integrationsForAllUsers = getIntegrationsForAllUsers(
    organisationUsers.value
  );
  const altoIntegrations = integrationsForAllUsers.filter(
    (integration) => integration?.crmName === crmName.ALTO
  );
  const activeIntegrations = altoIntegrations.filter(({ accountId }) => {
    return accountId !== undefined && accountId !== "";
  });

  return (
    altoIntegrations.length > 0 &&
    activeIntegrations.length === altoIntegrations.length
  );
});

const organisationBilling = ref([]);
const isFetchingOrganisationBilling = ref(false);

const fetchOrganisationBilling = async (organisationId) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();

  isFetchingOrganisationBilling.value = true;
  try {
    const response = await axios.get(
      `${MOVEREADY_FINANCES_API_URL}/orders/billing-info/${organisationId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisationBilling.value = response.data || null;

    if (Array.isArray(response.data) && response.data.length > 0) {
      stripeCustomerId = response.data[0].externalCustomerId;
    }
  } catch (ex) {
    organisationBilling.value = [];
    console.error(ex);
  } finally {
    isFetchingOrganisationBilling.value = false;
  }

  return organisationBilling.value;
};

/**
 * This is checking for an instance of a single "subscription" order.
 * Will not be useful if we have multiple subscriptions.
 */
const hasSubscription = computed(() => {
  return organisationOrders.value.some(
    (order) => order?.type === "subscription"
  );
});

let stripeCustomerId = null;

const createStripeCustomerForOrganisation = async ({
  organisationId,
  userId,
}) => {
  try {
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = getAccessToken();
    const response = await axios.post(
      `${MOVEREADY_FINANCES_API_URL}/orders/customers/organisations/${organisationId}`,
      {
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    stripeCustomerId = response.data?.stripeCustomerId;
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "organisations.js",
      message: `Failed to setup Stripe customer for organisation`,
      params: {
        userId,
        organisationId,
      },
    });
  }

  return stripeCustomerId;
};

let stripeClientSecret = null;

const createPaymentIntent = async (userId) => {
  try {
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = getAccessToken();
    const response = await axios.post(
      `${MOVEREADY_FINANCES_API_URL}/orders/setup-payment-intent`,
      {
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    stripeClientSecret = response.data?.clientSecret;
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "organisations.js",
      message: `Failed to setup payment intent`,
      params: {
        userId,
      },
    });
  }

  return stripeClientSecret;
};

const createSubscription = async ({ setupIntentId, userId }) => {
  try {
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = getAccessToken();
    await axios.post(
      `${MOVEREADY_FINANCES_API_URL}/orders`,
      {
        setupIntentId,
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "organisations.js",
      message: `Failed to create a subscription`,
      params: {
        setupIntentId,
        userId,
      },
    });
  }

  return stripeClientSecret;
};

const fetchOrganisationUsage = async (organisationId) => {
  try {
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = getAccessToken();
    await axios.get(
      `${MOVEREADY_FINANCES_API_URL}/orders/organisations/${organisationId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "organisations.js",
      message: `Failed to fetch organisation usage`,
      params: {
        organisationId,
      },
    });
  }
};

const organisationInvoices = ref([]);
const isFetchingOrganisationInvoices = ref(false);

const fetchOrganisationInvoices = async (organisationId) => {
  try {
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = getAccessToken();
    const usageResponse = await axios.get(
      `${MOVEREADY_FINANCES_API_URL}/orders/organisations/${organisationId}/payments`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisationInvoices.value = usageResponse.data;
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "organisations.js",
      message: `Failed to fetch organisation invoices`,
      params: {
        organisationId,
      },
    });
  }
};
const isDownloadingInvoice = ref(false);

const downloadOrganisationInvoice = async ({ paymentId, fileId }) => {
  isDownloadingInvoice.value = true;

  try {
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = getAccessToken();
    const downloadResponse = await axios.get(
      `${MOVEREADY_FINANCES_API_URL}/orders/payments/${paymentId}/files/${fileId}`,
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    const filename = filenameFromHeaders(downloadResponse.headers);
    const data = downloadResponse.data;
    createDownloadableFromBlob({ data, filename });
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "organisations.js",
      message: `Failed to download organisation invoice`,
      params: {
        paymentId,
        fileId,
      },
    });
  } finally {
    isDownloadingInvoice.value = false;
  }
};

const fetchOrganisationOrders = async (organisationId) => {
  try {
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = getAccessToken();
    const ordersResponse = await axios.get(
      `${MOVEREADY_FINANCES_API_URL}/orders/organisations/${organisationId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    console.log("ordersResponse.data", ordersResponse.data);
    organisationOrders.value = ordersResponse.data;
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "organisations.js",
      message: `Failed to fetch organisation orders`,
      params: {
        organisationId,
      },
    });
  }

  return stripeClientSecret;
};

const updateOrganisation = async ({
  organisationName = null,
  organisationLogoFileData = null,
  address = null,
  organisationId,
}) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();
  const organisationData = {};

  if (organisationName) {
    organisationData.organisationName = organisationName;
  }

  if (organisationLogoFileData) {
    organisationData.organisationLogoFileData = organisationLogoFileData;
  }

  if (address) {
    organisationData.address = address;
  }

  const newOrganisation = await axios.patch(
    `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}`,
    organisationData,
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    }
  );
  organisations.value = [...organisations.value, newOrganisation.data];

  return newOrganisation;
};

export default function useOrganisations() {
  return {
    conveyancers,
    createOrganisation,
    createOrganisationOwner,
    createPaymentIntent,
    createStripeCustomerForOrganisation,
    createSubscription,
    downloadOrganisationInvoice,
    fetchOrganisation,
    fetchOrganisationBilling,
    fetchOrganisationByInviteToken,
    fetchOrganisationInvoices,
    fetchOrganisationOrders,
    fetchOrganisationOwners,
    fetchOrganisationUsage,
    fetchOrganisationUsers,
    findConveyancersByOrganisationId,
    findOrganisationById,
    findOrganisations,
    findOrganisationsAdmin,
    hasConveyancers,
    hasSubscription,
    interectOrganisationsAndOwners,
    isAltoIntegrationActive,
    isAltoIntegrationAvailable,
    isDownloadingInvoice,
    isFetchingOrganisation,
    isFetchingOrganisationBilling,
    isFetchingOrganisationInvoices,
    isFetchingOrganisationUsers,
    isFindingOrganisations,
    organisation,
    organisationBilling,
    organisationInvoices,
    organisationLogoUrl,
    organisationName,
    organisationOrders,
    organisationOwners,
    organisations,
    organisationsWithOwners,
    organisationUsers,
    sortOrganisationByOrganisationName,
    stripeClientSecret,
    stripeCustomerId,
    updateOrganisation,
    updateOrganisationAsAdmin,
  };
}
