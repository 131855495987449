import redirectIfNoConveyancerParticipant from "../guards/redirectIfNoConveyancerParticipant";

export default [
  {
    path: "manage/participants/add/lawyer",
    name: "participant.conveyancer.add",
    component: () => import("../../views/conveyancer/AddConveyancer.vue"),
    meta: {
      title: "Your property lawyer",
    },
  },
  {
    path: "manage/participants/add/lawyer/review",
    name: "participant.conveyancer.review",
    beforeEnter: redirectIfNoConveyancerParticipant,
    component: () => import("../../views/conveyancer/ReviewConveyancer.vue"),
    meta: {
      title: "Review your property lawyer",
    },
  },
  {
    path: "property-pack/complete",
    name: "property-pack.complete",
    beforeEnter: redirectIfNoConveyancerParticipant,
    component: () => import("../../views/conveyancer/ConveyancerComplete.vue"),
    meta: {
      title: "Complete",
    },
  },
  {
    path: "manage/sale-ready/review",
    name: "sale-ready.review",
    component: () => import("../../views/conveyancer/ConnellsConfirmation.vue"),
    meta: {
      title: "Your property lawyer",
    },
  },
  {
    path: "manage/sale-ready/complete",
    name: "sale-ready.complete",
    component: () => import("../../views/conveyancer/ConnellsComplete.vue"),
    meta: {
      title: "Complete",
    },
  },
];
