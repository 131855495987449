import axios from "axios";
import useAuth from "@/composables/auth";
import { MOVEREADY_ORGANISATION_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref } from "vue";

const { getAccessToken, userId } = useAuth();
const organisationInvites = ref([]);
const isFetchingOrganisationInvites = ref(false);

const fetchOrganisationInvites = async (organisationId) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isFetchingOrganisationInvites.value = true;

  try {
    const response = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/invites/organisations/${organisationId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisationInvites.value = response?.data || [];
    return organisationInvites.value;
  } catch (ex) {
    // A 404 is no invites, but return an empty array instead.
    if (ex?.response?.status === 404) {
      organisationInvites.value = [];
      return organisationInvites.value;
    }

    throw ex;
  } finally {
    isFetchingOrganisationInvites.value = false;
  }
};

const createOrganisationInvite = async ({
  organisationId,
  inviteeEmail,
  inviteeFirstName,
  inviteeLastName,
  inviteePhone,
  inviteeOffices,
}) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();
  const invitePayload = {
    email: inviteeEmail,
    inviteeFirstName,
    inviteeLastName,
    phone: inviteePhone,
  };

  if (inviteeOffices.length) {
    invitePayload.offices = inviteeOffices;
  }
  await axios.post(
    `${MOVEREADY_ORGANISATION_API_URL}/invites/organisations/${organisationId}/users/${userId.value}/clients`,
    invitePayload,
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    }
  );
};

const acceptOrganisationInvite = async ({
  inviteeUserId,
  organisationId,
  token,
}) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    await axios.patch(
      `${MOVEREADY_ORGANISATION_API_URL}/invites/organisations/${organisationId}/users/${inviteeUserId}/token/${token}/accept`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    // A 409 means that the invite has been accepted.
    if (ex.response.status === 409) {
      return ex.response;
    }

    throw ex;
  }
};

const deleteOrganisationInvite = async (inviteId) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  await axios.delete(
    `${MOVEREADY_ORGANISATION_API_URL}/invites/${inviteId}/clients`,
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    }
  );
};

export default function useInvites() {
  return {
    acceptOrganisationInvite,
    createOrganisationInvite,
    deleteOrganisationInvite,
    fetchOrganisationInvites,
    isFetchingOrganisationInvites,
    organisationInvites,
  };
}
