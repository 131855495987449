import Honeybadger from "@honeybadger-io/js";
import axios from "axios";
import idvStatus from "@/enums/idv-status";
import useAuth from "@/composables/auth";
import {
  MOVEREADY_AML_API_URL,
  MOVEREADY_ORGANISATION_API_URL,
  MOVEREADY_PDTF_API_URL,
} from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";

const identityProvider = Object.freeze({
  ONFIDO: "onfido",
  SIMPLY: "simply",
  VALIDATE: "validate",
  YOTI: "yoti",
});
const DEFAULT_IDENTITY_PROVIDER = identityProvider.ONFIDO;
const identityProviderName = Object.freeze({
  [identityProvider.ONFIDO]: "Onfido",
  [identityProvider.YOTI]: "Yoti",
  [identityProvider.SIMPLY]: "Simply",
  [identityProvider.VALIDATE]: "Validate by LMS",
});

const { getAccessToken, userId } = useAuth();

const applicantId = ref(null);
const applicantToken = ref(null);
const onFidoError = ref(null);
const idvReports = ref([]);

const createApplicant = async (
  transactionId,
  { firstName, lastName, dateOfBirth, mobileNumber, residentialAddress }
) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    const response = await axios.post(
      `${MOVEREADY_AML_API_URL}/identity/applicants`,
      {
        transactionId,
        userId: userId.value,
        firstName,
        lastName,
        dateOfBirth,
        mobileNumber,
        residentialAddress,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );

    if (response?.data?.id) {
      applicantId.value = response.data.id;
    }
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Failed to create applicant (address format issue)",
      name: "indentity.js",
      params: {
        userId: userId.value,
        transactionId,
      },
    });

    if (ex?.response?.status === 422) {
      onFidoError.value = "The address provided is not valid.";
      throw new Error(onFidoError.value);
    }

    onFidoError.value = ex;
    throw ex;
  }
};

const fetchApplicantToken = async () => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    const response = await axios.get(
      `${MOVEREADY_AML_API_URL}/identity/applicants/${applicantId.value}/sdk-token`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );

    if (response?.data?.SDKToken) {
      applicantToken.value = response.data.SDKToken;
    }
  } catch (ex) {
    onFidoError.value = ex;
    Honeybadger.notify(ex, {
      message: "Failed to fetch applicant token",
      name: "indentity.js",
      params: {
        applicantId: applicantId.value,
      },
    });
    Honeybadger.notify(ex, {
      name: "indentity.js",
      message: "Failed to fetch an applicant token",
      params: {
        userId: userId.value,
        applicantId: applicantId.value,
      },
    });
    throw new Error(ex);
  }
};

const createCheck = async ({ transactionId }) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    await axios.post(
      `${MOVEREADY_AML_API_URL}/identity/checks`,
      {
        applicantId: applicantId.value,
        userId: userId.value,
        transactionId,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    onFidoError.value = ex;
    Honeybadger.notify(ex, {
      name: "indentity.js",
      message: "Failed to create a check",
      params: {
        applicantId: applicantId.value,
        userId: userId.value,
        transactionId,
      },
    });
    throw new Error(
      "Please refresh the page and try again or contact us for support."
    );
  }
};

const createApplicantAndToken = async (transactionId, participant) => {
  try {
    validateParticipantAsApplicant(participant);

    await createApplicant(transactionId, {
      firstName: `${participant.name.firstName} ${
        participant.name?.middleName || ""
      }`.trim(),
      lastName: participant.name.lastName,
      dateOfBirth: participant.dateOfBirth,
      mobileNumber: participant.phone,
      residentialAddress: {
        line1: participant.address.line1,
        line2: participant.address.town,
        postcode: participant.address.postcode,
        province: participant.address?.province || "",
        countryCode: participant.address.countryCode,
      },
    });

    await fetchApplicantToken();
  } catch (ex) {
    onFidoError.value = ex;
    Honeybadger.notify(ex, {
      message: "Failed to create applicant and fetch token",
      name: "indentity.js",
      params: {
        transactionId,
      },
    });
    throw new Error(ex);
  }
};

// Can't purely validate against the schema, as OnFido requires additional data
// not mandated by the PDTF `/participants`.
const validateParticipantAsApplicant = (participant) => {
  if (
    participant?.name?.firstName &&
    participant?.name?.lastName &&
    participant?.dateOfBirth &&
    participant?.address?.line1 &&
    participant?.address?.countryCode &&
    participant?.address?.postcode
  ) {
    return true;
  }

  throw new Error(
    "No participant data available. Please provide your name, address, mobile, and date of birth."
  );
};

const fetchReports = async (transactionId) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    const response = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/identity/reports/transactions/${transactionId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    idvReports.value = response.data;
  } catch (ex) {
    if (ex?.response?.status === 404) {
      idvReports.value = [];
    } else {
      Honeybadger.notify(ex, {
        name: "indentity.js",
        message: "Failed to fetch an applicant reports",
        params: {
          transactionId,
        },
      });
    }
  }
};

const fetchReportsByUserId = (userIdToFilterBy) => {
  const reportsForUser = idvReports.value.reduce(
    (acc, { userId, idvReports }) => {
      if (userId === userIdToFilterBy && Array.isArray(idvReports)) {
        return [...acc, ...idvReports];
      }

      return acc;
    },
    []
  );

  return reportsForUser;
};

const mapReportsToFiles = (idvReports) => {
  const completedIdvReports = idvReports.filter(
    ({ status }) => status === idvStatus.COMPLETED
  );

  const idvFiles = completedIdvReports.reduce(
    (
      fileCollection,
      { applicant = {}, evidenceDocuments = [], idvReports = [] }
    ) => {
      const { firstName, lastName } = applicant;

      const formattedApplicantFileName = `${firstName} ${lastName}`.replace(
        " ",
        "_"
      );

      const reportsAsFiles = idvReports.map(
        ({ signedUrl, displayName, formattedFileName }) => {
          let sourceName = "";
          let poster = require("../assets/images/back-office/posters/onfido.png");
          const isValidateReport = displayName
            .toLowerCase()
            .includes("validate");
          const isOnfido = displayName.toLowerCase().includes("applicant");
          let formattedDisplayName = displayName;

          if (isValidateReport) {
            poster = require("../assets/images/back-office/posters/validate.png");
            sourceName = "Validate by LMS";
          } else if (isOnfido) {
            poster = require("../assets/images/back-office/posters/identity-report.png");
            sourceName = "Onfido";
            formattedDisplayName = `IDV_report_${formattedApplicantFileName}.pdf`;
          }

          return {
            displayName: formattedDisplayName,
            poster,
            name: formattedFileName,
            isElectronicRecord: true,
            sourceName,
            url: signedUrl,
          };
        }
      );

      const evidencesAsFiles = evidenceDocuments.map(
        ({ formattedFileName, signedUrl, displayName }) => {
          const displayNameTranslation = {
            "applicant_selfie.png": `Selfie for ${firstName} ${lastName}`,
            "document_capture.jpeg": `ID for ${firstName} ${lastName}`,
          };

          const sourceNameTranslation = {
            "applicant_selfie.png": `Onfido (live selfie photo evidence)`,
            "document_capture.jpeg": `Onfido (ID photo evidence)`,
          };

          const fileNameTranslation = {
            "applicant_selfie.png": `IDV_selfie_${formattedApplicantFileName}.pdf`,
            "document_capture.jpeg": `IDV_document_${formattedApplicantFileName}.pdf`,
          };

          return {
            displayName: displayNameTranslation[displayName] || displayName,
            poster: signedUrl,
            name: fileNameTranslation[formattedFileName] || displayName,
            isElectronicRecord: true,
            sourceName: sourceNameTranslation[displayName] || "OnFido",
            url: signedUrl,
          };
        }
      );

      return [...fileCollection, ...reportsAsFiles, ...evidencesAsFiles];
    },
    []
  );

  return idvFiles;
};

const idvReportsAsFiles = computed(() => mapReportsToFiles(idvReports.value));

const isFetchingIdentityProvider = ref(false);

const fetchIdentityProvider = async (transactionId) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    const identityProviderResponse = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/transactions/${transactionId}/idv-provider`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    const identityProvider = identityProviderResponse.data;

    return identityProvider || DEFAULT_IDENTITY_PROVIDER;
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "indentity.js",
      message: "Failed to fetch the identity provider.",
      params: {
        transactionId,
      },
    });
    // Maybe throw an exception instead of using a default?
    return DEFAULT_IDENTITY_PROVIDER;
  } finally {
    isFetchingIdentityProvider.value = false;
  }
};

export default function useIdentity() {
  return {
    applicantId,
    applicantToken,
    createApplicant,
    createApplicantAndToken,
    createCheck,
    fetchApplicantToken,
    validateParticipantAsApplicant,
    fetchReports,
    idvReports,
    mapReportsToFiles,
    fetchReportsByUserId,
    idvReportsAsFiles,
    identityProvider,
    fetchIdentityProvider,
    isFetchingIdentityProvider,
    identityProviderName,
  };
}
