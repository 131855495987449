import Honeybadger from "@honeybadger-io/js";
import useAuth from "@/composables/auth";
import useInvites from "@/composables/invites";
import useUserProfile from "@/composables/userProfile";
import useVerifiedClaims from "@/composables/verifiedClaims";
import { usePDTF } from "@/composables/pdtf";

const { fetchTransaction } = usePDTF();
const { fetchVerifiedClaims } = useVerifiedClaims();
const { isClientForTransaction } = useUserProfile();
const { userId } = useAuth();

const redirectBasedOnInviteStatus = async (to, from, next) => {
  const { token } = to.query;
  const { transactionId } = to.params;
  const { acceptInvite } = useInvites();
  const { fetchUserProfile } = useUserProfile();

  try {
    await acceptInvite({
      inviteeUserId: userId.value,
      transactionId,
      token,
    });
  } catch (ex) {
    const status = ex?.response?.status || "500";

    Honeybadger.notify(ex, {
      message: "Accept invite has failed.",
      name: "redirectBasedOnInviteStatus.js",
      params: {
        transactionId,
        userId: userId.value,
      },
    });

    next({
      name: "invites.error",
      params: { transactionId },
      query: {
        status: `invite/${status}`,
      },
    });
  }

  try {
    /**
     * Load the state to redirect the user based on whether
     * they are the first (primary) Seller, or a Co-seller.
     *
     * Fetching the user profile to ensure we get the now updated
     * user data for the transaction.
     */
    await Promise.all([
      fetchTransaction(transactionId),
      fetchVerifiedClaims(transactionId),
      fetchUserProfile(),
    ]).catch((ex) => {
      Honeybadger.notify(ex, {
        message:
          "Failed to load transaction, claims, or user profile after invite acceptance.",
        name: "redirectBasedOnInviteStatus",
        params: {
          transactionId,
          userId: userId.value,
        },
      });
      next({
        name: "invites.error",
        params: { transactionId },
      });
    });

    if (isClientForTransaction(transactionId)) {
      return next({
        name: "property.view",
        params: { transactionId },
      });
    }

    // For buyer or tenant, send them to the restricted flow.
    return next({
      name: "participants.identity.introduction",
      params: { transactionId },
    });
  } catch (ex) {
    console.error(ex);
    const status = ex?.response?.status || "";

    return next({
      name: "invites.error",
      params: { transactionId },
      query: {
        status,
        token,
      },
    });
  }
};

export default redirectBasedOnInviteStatus;
