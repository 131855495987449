import userTaskRoutes from "./routes/userTasks";
import externalBaspiRoutes from "./routes/externalBaspi";
import developmentRoutes from "./routes/development";
import invitesRoutes from "./routes/invites";
import identityRoutes from "./routes/identity";
import backOfficeRoutes from "./routes/backOffice";
import legalNoticesRoutes from "./routes/legalNotices";
import altoRoutes from "./routes/alto";
import propertyLawyerRoutes from "./routes/propertyLawyer";
import requiresAuth from "./guards/requiresAuth";
import sellerPaymentRoutes from "./routes/sellerPayment";
import reapitRoutes from "./routes/reapit";
import contentRoutes from "./routes/content";
import redirectUserHome from "./guards/redirectUserHome";
import redirectIfLoggedIn from "./guards/redirectIfLoggedIn";

export default [
  {
    path: "/home",
    name: "user.home",
    beforeEnter: [requiresAuth, redirectUserHome],
  },
  {
    path: "/dashboard",
    name: "mover.dashboard",
    component: () => import("../views/UserHome.vue"),
    meta: {
      title: "User home",
      isAccountPage: true,
    },
    beforeEnter: requiresAuth,
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("../views/auth/SignUp.vue"),
    beforeEnter: redirectIfLoggedIn({ name: "user.home" }),
    meta: {
      title: "Sign up",
      isAccountPage: true,
      bodyClass: "body--auth",
    },
  },
  {
    path: "/agent/sign-up",
    name: "agent.sign-up",
    component: () => import("../views/auth/AgentSignUp.vue"),
    beforeEnter: redirectIfLoggedIn({ name: "user.home" }),
    meta: {
      title: "Sign up your agency",
      bodyClass: "body--sign-up",
    },
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("../views/auth/SignIn.vue"),
    meta: {
      title: "Sign in",
      isAccountPage: true,
      bodyClass: "body--auth",
    },
  },
  {
    path: "/sign-in/confirm",
    name: "sign-in.confirm",
    component: () => import("../views/auth/CompleteSignIn.vue"),
    beforeEnter: redirectIfLoggedIn({ name: "user.home" }),
    meta: {
      title: "Confirm sign-in",
      isAccountPage: true,
      bodyClass: "body--auth",
    },
  },
  {
    path: "/sign-out",
    alias: "/logout",
    name: "sign-out",
    component: () => import("../views/auth/SignOut.vue"),
    meta: {
      title: "Sign out",
      isAccountPage: true,
      bodyClass: "body--auth",
    },
  },
  {
    path: "/account",
    name: "user.account",
    component: () => import("../views/auth/AuthAccount.vue"),
    meta: {
      title: "My account",
      isAccountPage: true,
    },
    beforeEnter: requiresAuth,
  },
  /**
   * The BASPI flow is a set of child routes. The parent
   * route could manage the state and maybe use provide/inject
   * to handle the schema and data instead of using the
   * beforeEnter navigation guard.
   */
  {
    path: "/sale/:transactionId",
    component: () => import("../views/TransactionRouter.vue"),
    meta: {
      title: "Property",
      isAccountPage: true,
    },
    beforeEnter: requiresAuth,
    children: [
      {
        path: "manage",
        name: "property.view",
        component: () => import("../views/property/SaleView.vue"),
        meta: {
          title: "Property Pack",
        },
        beforeEnter: requiresAuth,
      },
      {
        path: "view/:pdtfPath+",
        name: "buyer-review.detail",
        component: () => import("../views/property/PropertyPackDetail.vue"),
        meta: {
          title: "Property Pack",
        },
      },
      // Regression fix.
      {
        path: "view/tasks",
        name: "buyer-review.tasks",
      },
      ...userTaskRoutes,
      ...propertyLawyerRoutes,
      ...identityRoutes,
      ...sellerPaymentRoutes,
    ],
  },
  {
    path: "/sale/:transactionId",
    component: () => import("../views/PublicTransactionRouter.vue"),
    meta: {
      title: "Property",
      isAccountPage: true,
    },
    children: [
      {
        path: "view",
        name: "property-pack.view",
        component: () => import("../views/property/PropertyPack.vue"),
        meta: {
          title: "Property Pack",
        },
      },
    ],
  },
  ...contentRoutes,
  ...invitesRoutes,
  ...backOfficeRoutes,
  ...legalNoticesRoutes,
  ...developmentRoutes,
  ...externalBaspiRoutes,
  ...reapitRoutes,
  ...altoRoutes,
];
